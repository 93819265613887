import React from 'react';

export const Barcelona = {
    "name": "Barcelona",
    "link": "barcelona",
    "country": "Spain",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50380499087_10bb33f9a6_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380499087_3a1ae7ba68_o.jpg",
    "caption": "One eye to the Mediterranean.",

    "description": "Barcelona is one of the most popular destinations in Europe. The city is home to millions of residents and visitors enjoying all the delicious sangria and gin, mouthwatering food, amazing beaches, warm weather, a lot of history and culture, and above all the magnific architecture of the town. At the same time this is one of the most vibrant and energetic cities of the world.",

    "nrDays": <p>
        We really think to get a feel for Barcelona you should plan your trip for at least <b>4 days</b>. You can visit the major points in
        less time, but you will do yourself a disservice if you rush your visit. So, to be amazed by this wonderful culture and architecture take your time
        to embrace the city's environment. If you have time to extend your visit you can make an extra day trip to <b>Girona</b> or to the <b>PortAventura World</b>.
    </p>,

    "whenVisit":<p>
        The perfect time to visit Barcelona is from April to July. You can relax on the spectacular beaches of Barcelona and enjoy its
        outdoor events and festivals. However, if sightseeing is your main focus then you really should try to visit between <b>March to May</b> or <b>September to November</b>,
        as tourists are by far in fewer numbers and the queues will be shorter.
    </p>,

    "mobility": <p>
        Most of our itinerary is to be done on foot. Barcelona is a very walkable and temperate city with mild weather all year around. You'll embrace
        the beauty of the streets and see the day to day of the locals. However the public transportation is quite developed here too.
        <br/><br/>
        You have a lot of bus, tram and metro stops that could take you anywhere in the city. It is also a good option if your hotel is a little
        dislocated from the city centre. You can take a single ticket for 2,40€ that is valid for one hour in metro and bus. However, the most
        economic option is a <b>T-Casual card</b> with 10 trips. It costs around 11€ and has 10 tickets valid as single ones, the best option in
        our opinion. You can also take a taxi, the prices here are not so expensive as in other European big cities.
    </p>,

    "safety": <p>
        Violent crime is very rare in Barcelona, but <b>petty crime</b> (bag-snatching, pickpocketing) is, as always, a big problem.
        You're at your most vulnerable when dragging around luggage to or from your stay. Be sure you know perfectly well your route
        before arriving to the city. Be careful with your belongings, particularly in crowded areas and in <b>crowded buses and metros</b>.
    </p>,

    "itinerary": {
        "description": <p>This is a city of late-night dinners, late morning, and afternoon siestas. You will enjoy your stay here if
            you take your time to see all the major spots. Here is a 4-day itinerary suggested by us, with a day trip to Montserrat.
            </p>,
        "days": [
            {
                "description": <p>
                As in other cities, we suggest to start your trip with a free walking tour. It's always a good option to get you to know about the
                city and the surroundings more easily and to learn more about the history of the place. After finishing your tour head to
                the <b>Gothic Quarter</b> and walk in the winding streets and see the historic buildings by yourself in one of the most beautiful
                areas of the city. In this neighborhood you will find the <b>Barcelona History Museum</b>, <b>The Grand Royal Palace</b>, <b>Chapel of Santa Àgata</b> and the <b>Barcelona Cathedral</b>.
                Some of those spots you will have already visited during the walking tour.
                <br/><br/>
                Take a late lunch and continue your trip to explore the Gaudi's architecture by visiting the <b>Park Güell</b>. At the evening enjoy an amazing sunset at <b>Bunkers del Carmel</b>.
                For your night find a good place for dinner at the city centre and go to a Flamenco show at the Plaza Real.
                </p>,
                "image":"https://live.staticflickr.com/65535/50498666857_7597e77706_o.jpg",
                "caption": "Park Güell."
            },
            {
                "description": <p>
                    On your second day, you will continue to be amazed by the magnifique architecture of the city. Go for an early visit to
                    the <b>La Sagrada Familia</b>, the gem of the city. Take a time to visit the interior if you are able to. After that continue
                    your walk and visit to other famous spots, <b>Casa Batlló</b> and <b>Casa Milà</b>. Enjoy your lunch at <b>La Boqueria</b>, a public local
                    market with a lot of offer.
                    <br/><br/>
                    At the afternoon go to <b>Tibidabo</b>, Barcelona's tallest peak, and visit the Cathedral del
                    Sagrat Cor and the old-school amusement park. The views from this point are insane. When you return to the city, try a good ramen in the Gothic Quarter and take a walk along <b>La Rambla</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50498666767_3993e3bf60_o.jpg",
                "caption": "La Sagrada Familia."
            },
            {
                "description": <p>
                In the third we recommend a day trip to <b>Montserrat Mont</b>. It is a great getaway from Barcelona's urban atmosphere. You can get there by train, and the journey takes about one hour.
                Then, take a cable car or funicular up to the mountain, visit the famous shrine of <b>Black Madonna</b> and walk all around the park.
                At night, when you return to the city, try to taste a good paella to recover your strengths, but remember this is not a traditional dish from this city.
                </p>,
                "image": "https://live.staticflickr.com/65535/50498517036_912d696575_o.jpg",
                "caption": "View from Montserrat Mount."
            },
            {
                "description":<p>
                On your last day in the city, and with still so much to see, start in the early morning with a walk by the <b>Passeig de Gràcia</b> towards the <b>Plaça Catalunya</b>.
                Take a left onto Ronda de Sant Pere and you will arrive to the <b>Arc de Triomf</b> and the <b>Ciutadella Park</b>.
                <br/><br/>
                After lunch catch a metro to the Paral. Here you will be able to get into another cable car and have another great views to the city.
                At the top you can visit the <b>Monjuic Castle</b> and a close by, the <b>Olympic Village</b>. During the climbdown you will be able to visit the <b>Joan Miró Foundation</b>, the <b>Museu National d'art de Catalunya</b>, and finally arrive at the <b>Palau National</b>.
                At night here you can see a famous show, the <b>Barcelona's Magic Fountain Show</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50497803083_b679b49f1a_o.jpg",
                "caption": "Arc de Triomf."
            },
        ]
    },

    "highlights": [
        {
            "name": "La Sagrada Familia",
            "description": "One of the most famous buildings in the world, and the most famous of Gaudi's work. Yet, it seems to never be finished. The church has been under construction for over than 100 years.",
            "image": "https://live.staticflickr.com/65535/50497803033_b51060454c_o.jpg"
        },
        {
            "name": "Park Guel",
            "description": "Located off La Rambla, Palau Guell (Guell Palace) building doesn’t jump out at you like other Gaudí structures. Built from 1886-88, it was designed for one of Gaudi’s patrons, Eusebi Güell.",
            "image": "https://live.staticflickr.com/65535/50498666547_0bfb5b552a_o.jpg"
        },
        {
            "name": "Casa Batlló",
            "description": "Casa Batlló is a building restored by Antoni Gaudí in the early 1900s. He spent 2 years on the project completely revamping the exterior, main floor, patio, and roof.",
            "image": "https://live.staticflickr.com/65535/50497802948_9dd0186dea_o.jpg"
        },
        {
            "name": "Tibidabo",
            "description": "This place located atop of Barcelona tallest peak, has a Cathedral that can be seen from just about anywhere in the city. You can go into the mighty church for free and even climb right to the top for a small fee. The views here are insane. And if that isn’t enough fun for you, attached here you will find a whole amusement park.",
            "image": "https://live.staticflickr.com/65535/50498516946_db4df26461_o.jpg"
        },
        {
            "name": "Gothic Quarter",
            "description": "With narrow streets and tiny alleys you will really enjoy this part of the city. During your walk here you will find other known spots such as the Cathedral of Barcelona on Pla de la Seu, Pont del Bisbe and Bishop's Bridge. As well as the hidden spider on the corner of Carrer de Montjuïc del Bisbe, Carrer del Paradís, the magic turtle mailbox on Carrer de Santa Llúcia and Plaça Reial.",
            "image": "https://live.staticflickr.com/65535/50498516911_df2ca32b18_o.jpg"
        },
        {
            "name": "PortAventura",
            "description": "PortAventura World is an entertainment resort in Salou and Vila-seca, Tarragona, on the Costa Daurada in Catalonia, around 80km from Barcelona. You can take the train to arrive there, and you will enjoy some of the most amazing entertainment roller-coasters in the world.",
            "image": "https://live.staticflickr.com/65535/50498666487_71cc67aeae_o.jpg"
        },
        {
            "name": "La Rambla",
            "description": "At 1,2km long, this street with a crowded walkway in the center has a lot of famous buildings along its path, as the Grand Teatre del Liceu, and the city's Opera House. ",
            "image": "https://live.staticflickr.com/65535/50497802843_5f58309986_o.jpg"
        },
        {
            "name": "La Boqueria",
            "description": "This market has an amazing group of food stalls and restaurants with traditional cuisine. It has been situated on the same spot for over a hundred years, right off La Rambla. This market is always full of tourists and locals, so expect it typically very busy.",
            "image": "https://live.staticflickr.com/65535/50498516831_35d9df187f_o.jpg"
        },
        {
            "name": "Arc de Triomf & Ciutadella Park",
            "description": "The Arc de Triomf is a triumphal arch in the city. It was built by architect Josep Vilaseca i Casanovas as the main access gate for the 1888 Barcelona World Fair. Cross over the road at the bottom and you’ll land in Ciutadella Park. Bring a blanket to sit down or simply wander around to your heart’s content.",
            "image": "https://live.staticflickr.com/65535/50498666467_a266a78832_o.jpg"
        },
        {
            "name": "Bunkers del Carmel",
            "description": "With a fantastic view to the city this is one of the must visit spots to enjoy the sunset during your trip. Take your time to enjoy the place and get nice pictures from here.",
            "image": "https://live.staticflickr.com/65535/50498666422_57ce67e996_o.jpg"
        },
        {
            "name": "Montjuic - Museu Nacional d’Art de Catalunya",
            "description": <p>
                This art museum houses Catalonia art, particularly Gothic, Renaissance, and Baroque works. The fountain out front has a spectacular free show that takes place on Fridays and Saturdays from 7pm-9pm (from October-December and February-April), and Thursdays-Sundays from 9pm-11:30pm (from May-September).
                <br/><br/>
                All the museums here and others around Barcelona can be visited for free at the first Sunday of every month.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498666397_eee72394b9_o.jpg"
        }
    ],

    "food": {
        "description": "Barcelona has a lot of different cultures living in the city, so you will find many different cuisines to taste. Here we will suggest some good restaurants that we recommend to try.",
        "restaurants": [
            {
                "name": "Bacoa Kiosko",
                "description": "In our opinion, the best burgers in town. With moderate price and quick service this place is a must visit if you are a burger lover.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498666372_483f01feeb_o.jpg"
            },
            {
                "name": "König Barcelona",
                "description": "Famous place in town to try \"Patatas Bravas\". Prices are cheap and the food is good, the menu includes burgers, sandwiches, tapas and more.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50497802708_925c0c263c_o.jpg"
            },
            {
                "name": "Bodega Biarritz 1881",
                "description": "If you’re in Barcelona, you’ll want to try some tapas. If you only have time to try tapas once, go here. You'll not be disappointed.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50497802683_e2b5481999_o.jpg"
            },
            {
                "name": "Chaka Khan",
                "description": "It’s a lovely place with very good atmosphere and very friendly staff! The food is excellent as well!",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498516701_ab46138225_o.jpg"
            },
            {
                "name": "Bar Xapako",
                "description": "Great tapas bar, with a very friendly and welcoming atmosphere. A cheap option to take.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50497802653_872e7e30ae_o.jpg"
            },
            {
                "name": "Colom",
                "description": "Colom is one of many restaurants in this part of the Gothic Quarter. Very famous for its paella.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50498516666_a2e1c7bab0_o.jpg"
            },
            {
                "name": "Con Gracia",
                "description": "The atmosphere is fantastic, the service is friendly and welcoming, and of the highest standards. The staffs descriptions of each dish is one of the highlights - very interesting!",
                "price": 4,
                "image": "https://live.staticflickr.com/65535/50498516636_1e97610145_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "Barcelona is a very big city and it's difficult to place yourself near all the attractions. Be sure you have a metro stop near your hotel and you will be able to get to all the main spots. We leave here some suggestions in different areas of the city:",
        "budget": [
            {
                "name": "Casa Gracia",
                "description": "Casa Gràcia is a modern hostel at the top of Barcelona’s Passeig de Gràcia, 300 m from Gaudí’s La Pedrera. It offers air-conditioned rooms and free WiFi in all areas.",
                "image": "https://live.staticflickr.com/65535/50497802573_5543d6e5fe_o.jpg",
                "link": "/"
            },
            {
                "name": "Primavera Hostel",
                "description": "Primavera hostel is placed in Barcelona’s Eixample district, 8 minutes’ walk from the Sagrada Familia. It has free WiFi and private rooms with a balcony.",
                "image": "https://live.staticflickr.com/65535/50498516571_71d59916cd_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Hotel Market",
                "description": "Hotel Market is located next to Barcelona’s San Antonio Market, 10 minutes' walk from MACBA Gallery. It also includes a famous restaurant inside the hotel building.",
                "image": "https://live.staticflickr.com/65535/50498516561_3ae23de020_o.jpg",
                "link": "/"
            },
            {
                "name": "Aparthotel Bcn Montjuic",
                "description": "Close to the Montjuic Hill this place offers a nice rooftop with a seasonal swimming pool.",
                "image": "https://live.staticflickr.com/65535/50498516546_2f0c482a52_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Hotel SB Glow **** Sup",
                "description": "This hotel has a restaurant, a swimming pool at the rooftop and a bar. It is a luxurious option for your stay in Barcelona.",
                "image": "https://live.staticflickr.com/65535/50498516541_8340eb1e43_o.jpg",
                "link": "/"
            },
            {
                "name": "Novotel Barcelona City",
                "description": "This modern hotel offers a free gym and a roof terrace with a swimming pool. It's located near to Glòries Shopping Centre and the Agbar Tower.",
                "image": "https://live.staticflickr.com/65535/50498516531_1915c9d3a9_o.jpg",
                "link": "/"
            }
        ]
    }
}
